#dropdownToggler {
    display: none;
}

.dropdown {
    transition: all .5s cubic-bezier(0.85, 0, 0.15, 1);
    top: -480px
}

.sidebar {
    height: 0;
    transition: all .3s cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
}

#dropdownToggler:checked ~ .sidebar {
    height: calc(100vh - 50px);
    padding-bottom: 50px;
}




.subNav {
    height: 0px;
    transition: all .5s cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
}

.subNavToggler:checked ~ .subNav {
    height: 185px;
}

.subNavChevron {
    transform: rotate(0deg);
    transition: all .5s cubic-bezier(0.4, 0, 0.2, 1);

}

.subNavToggler:checked ~ .subNavChevron {
   transform: rotate(-180deg)
}



@media (min-width: 768px) {
    #dropdownToggler:checked ~ .dropdown {
        top: 0
    }

    .sidebar {
        display: none;
    }
}


/* .sidebar {
    height: calc(100vh - 50px);
} */