.lookData {
    display: none;
}

.dataWrapper {
    backface-visibility: hidden;
    transition-delay: .3s;
    transition: transform .5s;
}
.dataWrapper:hover {
transform: scale(1.1);
background-color: white;
border: none;
}

.dataWrapper:hover > .lookData {
    display: block;
}