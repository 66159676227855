.progresstracker{
    background-image: url("https://res.cloudinary.com/budgit/image/upload/q_10/v1624207930/FIXOUROIL/ogp-banner_bishac.jpg");
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.dates{
    font-family: Poppins;
    font-style: normal;
    letter-spacing: 0.03cm;
    padding: 16px;
}
.dates h5{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #d3106f;
}
.dates p {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 40px;
}
div #percent {
    background: rgba(229, 229, 229, 0.36);
    box-shadow: inset 0px 1px 6px rgba(0, 0, 0, 0.22);
    border-radius: 20px;
}

div .per {
    /* width: 70%; */
    height: 12px;
    background-color: #7d7107;
    /* border-radius: 4px; */
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

@media (min-width:768px) and (max-width: 900px){
    .dates p {
        padding-bottom: 0px;
    }
    .dates {
        padding-right: 70px;
    }
}
@media (min-width:1024px) {
    .dates p {
        padding-bottom: 0px;
    }
}