.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  cursor: pointer;
}

.overlay > .overlay__content {
  transition: opacity 0.5s, transform 0.3s;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 50%;
}

.overlay:hover .overlay__content{
    opacity: 1;
    transform: translateY(-50%);
}

.focus {
  transition: 0.5s;
}

.focus[data-scaled="true"] {
  transform: scale(1.1);
  z-index: 1;
}
.focus[data-scaled="true"] > .topContent {
  display: none;
}

.focus[data-scaled="true"] > .overlay {
  background-color: rgba(0, 0, 0, 0.6);
}
.focus[data-scaled="false"] {
  transform: scale(1);
  z-index: 0;
}
