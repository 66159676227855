@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Ubuntu:wght@400;500;700&display=swap");

.layout {
  min-height: calc(100vh - 50px - 820px) !important;
}

.layout__wrapper {
  padding-right: 24px;
  padding-left: 24px;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

@media (min-width: 768px) {
  .layout {
    min-height: calc(100vh - 532px) !important;
  }

  .resourceItem {
    width: calc(50% - 18px);
  }

  .resourceItem:nth-child(even) {
    margin-right: 18px;
    margin-left: 18px;
  }

  .layout__wrapper {
    padding-right: 48px;
    padding-left: 48px;
  }
}


@media (min-width: 900px) {
  .layout {
    min-height: calc(100vh - 341px) !important;
  }
}



@media (min-width: 1024px) {
  .layout {
    min-height: calc(100vh - 339px) !important;
  }

  .resourceItem:nth-child(even) {
    margin-right: 0px;
    margin-left: 0px;
  }


  .resourceItem {
    width: calc(33.333333333333% - 26px);
    margin-right: 0px;
  }

  .resourceItem:nth-child(3n+2) {
    margin-left: 30px;
    margin-right: 30px;
}

  .layout__wrapper {
    width: 78%;
    margin-right:auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (min-width: 1280px) {


  .layout__wrapper {
    width: 1200px;
    margin-right:auto;
    margin-left: auto;
    padding-right: 48px;
    padding-left: 48px;
  }
}